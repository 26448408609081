package dev.moetz.twitcharchivemanager.design

import dev.moetz.materialize.mFooter
import react.ChildrenBuilder

fun ChildrenBuilder.mFooter() {
    mFooter(
        socialLink = "https://moetz.dev",
        pageName = "TwitchArchiveManager",
        gitUrl = "https://gitlab.moetz.dev/twitch/twitch-archive-manager",
        isOpenSource = false,
        linksOnLeftSide = listOf(
            "Privacy Policy" to "https://moetz.dev/privacy",
        )
    )
}
